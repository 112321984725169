import styled from 'styled-components';
import { mq } from 'stylesheets/utils';

export const Main = styled.main`
  padding-top: 80px;
  min-height: calc(100vh - 80px); /* 80px for fixed navbar height */
  display: flex;
  flex-direction: column;
  width: 100%;
  ${mq(768)} {
    padding-top: 60px;
    min-height: calc(100vh - 60px); /* 60px for fixed navbar height in mobile */
  }
`;

export const ProfileWrapper = styled(Main)`
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.innerProduct.gray};
  ${Main} {
    /* background-color: ${({ theme }) => theme.colors.white}; */
    padding: 24px 10px;
  }
`;
