import ReactSelect from 'react-select';
import styled from 'styled-components';

export const CommonSelect = styled(ReactSelect)`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  margin-bottom: 12px;
  & > div {
    /* z-index: 6; */
    box-shadow: none;
    border: 1px solid #c4c8e3;
    border-radius: 4px;
  }
  div[class$='-placeholder'] {
    white-space: nowrap;
  }
`;
