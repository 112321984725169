import Image from 'next/image';
import { useRouter } from 'next/router';
import ArrowDown from 'public/icons/arrow-down.svg';
import React, { useMemo } from 'react';
import useCollapse from 'react-collapsed';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { PartCategory } from 'types/Categories';
import { ListBody, ListDropdownBtn, ListHeader, ListLink, ListWrapper } from './Navbar.styled';

type Props = {
  category: PartCategory;
  setShowMainMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

function CollapseMenu({ category, setShowMainMenu }: Props) {
  const { push } = useRouter();
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
  const hasChildren = Boolean(category.children.length);
  const { data: profileCars } = useSelector((state: RootState) => state.profileCars);
  const favoriteCar = useMemo(() => profileCars?.find((car) => car.is_favorite), [profileCars]);

  return (
    <ListWrapper>
      <ListHeader {...getToggleProps()} className={!hasChildren ? 'no-children' : ''}>
        <ListLink
          onClick={
            !hasChildren
              ? () => {
                  push({
                    pathname: `/products`,
                    query: {
                      category_id: category.id,
                      ...(category.need_car && favoriteCar ? { car_id: favoriteCar.car.id } : {}),
                    },
                  });
                  setShowMainMenu(false);
                }
              : undefined
          }
        >
          {category.name}
        </ListLink>
        {hasChildren && (
          <ListDropdownBtn {...getToggleProps()} isExpanded={isExpanded}>
            <Image src={ArrowDown} alt="Dropdown Arrow" width={9.25} height={16} />
          </ListDropdownBtn>
        )}
      </ListHeader>
      {hasChildren && (
        <ListBody {...getCollapseProps()}>
          {category.children.map((cat) => (
            <CollapseMenu key={cat.id} category={cat} setShowMainMenu={setShowMainMenu} />
          ))}
        </ListBody>
      )}
    </ListWrapper>
  );
}

export default React.memo(CollapseMenu);
