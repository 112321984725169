import styled, { css } from 'styled-components';

export const CommonButton = styled.button<{
  readonly variant: 'default' | 'filled-red' | 'outlined';
  readonly disabled?: boolean;
}>`
  padding: 7px 16px;
  font-size: 1.6rem;
  font-weight: 400 /* rtl:500 */;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  line-height: 1.5;
  transition: 0.2s ease background-color, 0.2s ease box-shadow, 0.2s ease border-color;
  ${({ variant, theme }) =>
    variant === 'default'
      ? css`
          background-color: ${theme.colors.white};
          color: ${theme.colors.black};
          border: 1px solid ${theme.colors.lightGray};
          &:hover {
            background-color: ${theme.colors.lightBlue};
          }
          &:active {
            background-color: ${theme.colors.lightBlue};
          }
          &:disabled,
          &[disabled] {
            cursor: not-allowed;
            background-color: ${theme.colors.disabled};
          }
        `
      : variant === 'filled-red'
      ? css`
          background-color: ${theme.colors.brandColor};
          color: ${theme.colors.white};
          &:hover {
            background-color: ${theme.colors.brandColorHover};
          }
          &:active {
            background-color: ${theme.colors.brandColorActive};
          }
          &:disabled,
          &[disabled] {
            cursor: not-allowed;
            color: ${theme.colors.disabled};
          }
        `
      : variant === 'outlined'
      ? css`
          border: 1px solid ${({ theme }) => theme.colors.brandColor};
          color: ${({ theme }) => theme.colors.brandColor};
        `
      : css``}
`;
