import { useAuth } from '@Contexts/AuthContext/Auth.context';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import CarsIcon from 'public/icons/menu-cars.svg';
import OrdersIcon from 'public/icons/menu-orders.svg';
import AccountIcon from 'public/icons/user-account.svg';
import React, { useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import {
  NavbarDropdown,
  UserButton,
  UserImg,
  UserLi,
  UserMenuLink,
  UserMenuWrapper,
  Username,
  UserUl,
} from './Navbar.styled';

type Props = {};

export default React.memo(function UserMenu({}: Props) {
  const { t } = useTranslation('common');
  const { userData } = useAuth();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [imgSrc, setImgSrc] = useState<null | string>(userData?.avatar || null);
  const dropdownRef = useRef(null);

  useClickAway(dropdownRef, () => {
    setIsDropdownOpen(false);
  });

  return (
    <UserMenuWrapper ref={dropdownRef}>
      <UserButton onClick={() => setIsDropdownOpen((prev) => !prev)} isOpen={isDropdownOpen}>
        {imgSrc === null ? (
          <UserImg as="div">{userData?.full_name[0]}</UserImg>
        ) : (
          <UserImg src={imgSrc} alt={userData?.full_name} onError={() => setImgSrc(null)} />
        )}
      </UserButton>
      <NavbarDropdown isOpen={isDropdownOpen}>
        <Username>{userData?.full_name || userData?.email}</Username>
        <UserUl>
          <UserLi>
            <UserMenuLink href="/profile/edit">
              <Image src={AccountIcon} alt="Account" width={19.68} height={24} />
              <span>{t('my-account')}</span>
            </UserMenuLink>
          </UserLi>
          <UserLi>
            <UserMenuLink href="/profile/orders">
              <Image src={OrdersIcon} alt="Orders" width={17.5} height={20.1} />
              <span>{t('my-orders')}</span>
            </UserMenuLink>
          </UserLi>
          <UserLi>
            <UserMenuLink href="/profile/cars">
              <Image src={CarsIcon} alt="Cars" width={20.1} height={17.59} />
              <span>{t('my-cars')}</span>
            </UserMenuLink>
          </UserLi>
        </UserUl>
      </NavbarDropdown>
    </UserMenuWrapper>
  );
});
