import { CommonButton } from '@Components/common/Button';
import { CommonSelect } from '@Components/common/CommonSelect';
import Link from '@Components/common/Link';
import styled, { css } from 'styled-components';
import { mq, rgba } from 'stylesheets/utils';

export const NavbarWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  border-bottom: 1px solid #0c0c0c;
  user-select: none;
  z-index: 99;
  ${mq(768)} {
    height: 60px;
  }
`;
export const Inner = styled.div`
  display: flex;
  align-items: center;
`;
export const MenuBtn = styled.button`
  min-width: 36px;
  max-width: 36px;
  min-height: 36px;
  max-height: 36px;
  margin-right: 20px;
  transform: scale(-1, 1) /*rtl: scale(1, 1) */;
  ${mq(768)} {
    min-width: 28px;
    max-width: 28px;
    min-height: 28px;
    max-height: 28px;
    margin-right: 10px;
  }
`;
export const LogoLink = styled(Link)`
  display: flex;
  margin-right: 50px;
  min-width: 156.75px;
  ${mq(992)} {
    margin-right: 20px;
  }
  ${mq(576)} {
    min-width: 110px;
  }
`;
export const CartLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  margin-right: 20px;
  position: relative;
  ${mq(992)} {
    margin-right: 10px;
  }
`;
export const CartCount = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.cartCountColor};
  font-size: 0.8rem;
  font-weight: 600;
`;
export const NavLinks = styled.nav`
  margin-right: auto;
`;
export const NavUl = styled.ul`
  display: flex;
  align-items: center;
  gap: 40px;
  ${mq(992)} {
    gap: 10px;
  }
  ${mq(768)} {
    display: none;
  }
`;
export const NavLi = styled.li``;
export const NavLink = styled(Link)<{ readonly isActive: boolean }>`
  color: ${({ theme, isActive }) => (isActive ? theme.colors.brandColor : theme.colors.black)};
  font-weight: 400 /*rtl: 600 */;
  font-size: 1.6rem;
  ${mq(992)} {
    font-size: 1.4rem;
  }
  ${({ isActive }) =>
    isActive &&
    css`
      position: relative;
      &::after {
        content: '';
        position: absolute;
        bottom: -2px;
        left: -2px;
        right: -2px;
        height: 2px;
        border-radius: 2px;
        background-color: ${({ theme }) => theme.colors.brandColor};
      }
    `}
`;
export const NavActions = styled.div`
  display: flex;
  align-items: center;
`;
export const ChangeLanguageSelect = styled(CommonSelect)`
  margin-right: 24px;
  margin-bottom: 0;
  .lang__input-container {
    /* display: none; */
  }
  .lang__control {
    min-height: unset;
    padding: 0;
    min-width: 94px;
    ${mq(576)} {
      min-width: 70px;
      max-width: 70px;
      max-height: 29px;
    }
  }
  .lang__value-container {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    gap: 5px;
    justify-content: space-between;
    ${mq(576)} {
      padding: 4px;
    }
  }
  .lang__single-value {
    margin: 0;
    font-size: 1.4rem;
    line-height: 1.5;
  }
  .lang__option {
    padding: 3px 6px;
    font-size: 1.4rem;
    line-height: 1.5;
    cursor: pointer;
  }
`;

export const Overlay = styled.div<{ readonly isActive: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => rgba(theme.colors.darkBlue, 0.7)};
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 0.2s ease opacity, 0.2s ease visibility;
  transition-delay: 0.1s, 0.1s;
  z-index: 99;
  ${({ isActive }) =>
    isActive &&
    css`
      transition-delay: 0s, 0s;
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    `}
`;
export const MainMenuWrapper = styled.aside<{ readonly isOpen: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 340px;
  transition: 0.2s ease opacity, 0.2s ease visibility, 0.4s ease transform;
  transition-delay: 0.1s;
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(-100%)')};
  background-color: ${({ theme }) => theme.colors.white};
  user-select: none;
  display: flex;
  flex-direction: column;
  z-index: 100;
`;
export const MainMenuHeader = styled.div`
  height: 80px;
  background-color: ${({ theme }) => theme.colors.brandColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 40px;
`;
export const CloseBtn = styled.button`
  width: 24px;
  height: 24px;
`;
export const MainMenuBody = styled.div`
  flex: 1;
  overflow-y: auto;
  margin: 22.5px 0;
  padding-right: 20px;
`;
export const MenuHeader = styled.div`
  height: 58px;
  background-color: #ddd;
  display: flex;
  align-items: center;
  padding: 0 30px;
`;
export const ListLink = styled.div`
  font-size: 1.8rem /*rtl: 2rem */;
  color: ${({ theme }) => theme.colors.black};
  white-space: nowrap;
  max-width: calc(100% - 36px - 10px);
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  line-height: 58px;
`;
export const ListDropdownBtn = styled.button<{ readonly isExpanded?: boolean }>`
  min-width: 36px;
  max-width: 36px;
  min-height: 36px;
  max-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease transform;
  ${({ isExpanded }) =>
    isExpanded &&
    css`
      transform: rotate(-180deg);
    `}
`;
export const ListHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 40px;
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  transition: 0.2s ease background-color;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.lightBlue};
  }
  &.no-children {
    padding-left: 60px;
  }
`;
export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ListBody = styled.div`
  ${ListWrapper} {
    & {
      ${ListHeader} {
        padding-right: 20px;
        background-color: ${({ theme }) => theme.colors.white};
      }
      ${ListLink} {
        font-size: 1.6rem;
        font-weight: 400;
      }
    }
  }
`;
export const SearchBtn = styled.button`
  min-width: 36px;
  max-width: 36px;
  min-height: 36px;
  max-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  ${mq(992)} {
    margin-right: 8px;
  }
`;
export const NavButton = styled(CommonButton)`
  margin-right: 16px;
  &:last-child {
    margin-right: 0;
  }
  ${mq(992)} {
    margin-right: 8px;
  }
  ${mq(768)} {
    font-size: 1.4rem;
  }
  ${mq(576)} {
    &:last-child {
      display: none;
    }
  }
`;
export const UserImg = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  font-weight: 500;
  min-width: 36px;
  max-width: 36px;
  min-height: 36px;
  max-height: 36px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.brandColor};
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 101;
  position: relative;
  transition: 0.3s ease transform;
`;
export const UserButton = styled.button<{ readonly isOpen: boolean }>`
  padding: 5px;
  ${({ isOpen }) =>
    isOpen &&
    css`
      ${UserImg} {
        transform: translateX(-149px);
      }
    `}
`;
export const NavbarDropdown = styled.div<{ readonly isOpen: boolean }>`
  position: absolute;
  top: -2px;
  right: 0;
  width: 100%;
  min-width: 204px;
  max-width: 204px;
  min-height: 52px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 0.4s ease all;
  border: 1px solid ${({ theme }) => theme.colors.white};
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  z-index: 100;
  background-color: ${({ theme }) => theme.colors.lightBlue};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 4px;
  box-shadow: 0 2px 4px ${({ theme }) => rgba(theme.colors.black, 0.16)};
  z-index: 99;
  ${({ isOpen }) =>
    isOpen &&
    css`
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
      transition-delay: 0.1s;
    `}
`;
export const UserMenuWrapper = styled.div`
  position: relative;
  z-index: 99;
`;
export const Username = styled.span`
  font-size: 1.6rem;
  text-align: right;
  padding: 12px 15px 12px 60px;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-bottom: 4px;
  direction: ltr /*rtl: ltr */;
`;
export const UserUl = styled.ul`
  margin-bottom: 7.5px;
`;
export const UserLi = styled.li`
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.menuBorder};
  margin-bottom: 0.5px;
`;
export const UserMenuLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.black};
  span {
    margin-left: 20px;
  }
`;
