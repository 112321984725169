import NextLink from 'next/link';
import { CSSProperties, ReactNode } from 'react';
import { UrlObject } from 'url';

type Props = {
  onClick?: (event: React.MouseEvent<Element, MouseEvent>) => void;
  children: ReactNode;
  className?: string;
  href?: string | UrlObject | null;
  style?: CSSProperties;
  locale?: string;
  title?: string;
};

const Link = ({ onClick, children, className, href, locale, ...props }: Props) =>
  href ? (
    <NextLink {...props} href={href} locale={locale} passHref>
      <a {...props} onClick={onClick} className={className}>
        {children}
      </a>
    </NextLink>
  ) : (
    <a {...props} onClick={onClick} className={className}>
      {children}
    </a>
  );

export default Link;
