import { Spinner } from '@Components/common/Spinner';
import Image from 'next/image';
import CloseIcon from 'public/icons/close-icon.svg';
import LogoWhite from 'public/logo-white.svg';
import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { Col, Row } from 'styled-bootstrap-grid';
import CollapseMenu from './CollapseMenu';
import { CloseBtn, LogoLink, MainMenuBody, MainMenuHeader, MainMenuWrapper } from './Navbar.styled';

type Props = {
  showMainMenu: boolean;
  setShowMainMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

const MainMenu = forwardRef<HTMLElement, Props>(function MainMenu({ showMainMenu, setShowMainMenu }, menuRef) {
  const { data, isLoading } = useSelector((state: RootState) => state.categories);

  return (
    <MainMenuWrapper isOpen={showMainMenu} ref={menuRef}>
      <MainMenuHeader>
        <LogoLink href="/">
          <Image src={LogoWhite} alt="YourParts" width={156.75} height={24.41} />
        </LogoLink>
        <CloseBtn onClick={() => setShowMainMenu(false)}>
          <Image src={CloseIcon} alt="Close" width={24} height={24} />
        </CloseBtn>
      </MainMenuHeader>
      <MainMenuBody>
        {isLoading || !data ? (
          <Row justifyContent="center">
            <Col auto>
              <Spinner />
            </Col>
          </Row>
        ) : (
          data.map((category) => (
            <CollapseMenu setShowMainMenu={setShowMainMenu} key={category.id} category={category} />
          ))
        )}
      </MainMenuBody>
    </MainMenuWrapper>
  );
});

export default React.memo(MainMenu);
